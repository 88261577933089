import React from 'react';
import styles from './App.module.scss';
import { Header } from './components/Header/Header';
import { Main } from './pages/Main/Main';
import { Footer } from './components/Footer/Footer';

const App: React.FC = () => {
	return (
		<div className={styles.App}>
			<Header />
			<Main />
			<Footer />
		</div>
	);
}

export {App};
