import React from 'react';

const Phone: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M25.9009 5.66795C23.5354 3.30248 20.3902 1.99987 17.0451 2C16.4969 2 16.0527 2.4443 16.0527 2.99234C16.0527 3.54038 16.497 3.98468 17.0451 3.98468C19.8601 3.98455 22.5068 5.08075 24.4974 7.07139C26.4882 9.06202 27.5844 11.7088 27.5843 14.524C27.5843 15.072 28.0284 15.5163 28.5766 15.5163C29.1246 15.5163 29.5689 15.072 29.5689 14.5241C29.5691 11.1786 28.2665 8.03343 25.9009 5.66795Z" fill="white"/>
        <path d="M21.8188 14.5241C21.8188 15.0721 22.2631 15.5164 22.8112 15.5163C23.3593 15.5163 23.8034 15.072 23.8034 14.524C23.8032 10.7981 20.7714 7.7664 17.0452 7.76587C17.0451 7.76587 17.0453 7.76587 17.0452 7.76587C16.4972 7.76587 16.0529 8.21004 16.0527 8.75808C16.0527 9.30611 16.4969 9.75042 17.0449 9.75055C19.677 9.75095 21.8185 11.8923 21.8188 14.5241Z" fill="white"/>
        <path d="M19.6231 19.5836C18.1105 19.5053 17.3399 20.6302 16.9704 21.1706C16.6609 21.623 16.7768 22.2405 17.2292 22.5499C17.6815 22.8594 18.299 22.7435 18.6085 22.2911C19.0451 21.6527 19.2429 21.5523 19.5113 21.565C20.3701 21.666 23.7527 24.1447 24.0914 24.9198C24.1763 25.148 24.1731 25.3718 24.082 25.6439C23.7269 26.6979 23.139 27.4386 22.3816 27.7858C21.6621 28.1156 20.78 28.0857 19.8312 27.6996C16.2883 26.2556 13.193 24.2403 10.6314 21.7097C10.6304 21.7087 10.6293 21.7078 10.6284 21.7067C8.10306 19.1477 6.09152 16.0563 4.64985 12.5188C4.26363 11.5692 4.23373 10.687 4.56359 9.96759C4.91077 9.21023 5.65145 8.62237 6.70453 8.26764C6.97762 8.17608 7.20096 8.17317 7.42708 8.25732C8.20494 8.59723 10.6835 11.9797 10.7836 12.8288C10.7979 13.1072 10.6966 13.3049 10.0588 13.7407C9.60626 14.0498 9.48996 14.6673 9.79917 15.1198C10.1083 15.5723 10.7256 15.6885 11.1783 15.3794C11.7189 15.0103 12.8435 14.2418 12.7655 12.724C12.6795 11.1387 9.59554 6.94029 8.12198 6.39847C7.46664 6.15422 6.7773 6.14999 6.07234 6.38656C4.48618 6.92071 3.34063 7.87309 2.75951 9.14064C2.19586 10.3703 2.21372 11.7971 2.81178 13.2673C4.35467 17.053 6.51334 20.3655 9.22772 23.1129C9.23433 23.1197 9.24108 23.1263 9.24796 23.1328C11.9934 25.8421 15.302 27.9968 19.0827 29.5379C19.8398 29.8457 20.5854 29.9999 21.2984 29.9999C21.9698 29.9999 22.6125 29.8633 23.2087 29.59C24.4764 29.009 25.4287 27.8636 25.9632 26.2764C26.1992 25.5727 26.1954 24.8836 25.953 24.2314C25.4093 22.7538 21.2109 19.6699 19.6231 19.5836Z" fill="white"/>
    </svg>
);

export {Phone};