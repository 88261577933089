import React from 'react';

const Java: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17.4405 18.8049H17.3516V18.7551H17.591V18.8049H17.5026V19.0531H17.4405V18.8049ZM17.918 18.8178H17.9166L17.8285 19.0531H17.7881L17.7006 18.8178H17.6995V19.0531H17.641V18.7552H17.7271L17.8083 18.9662L17.8892 18.7552H17.9751V19.0531H17.9179L17.918 18.8178Z" fill="#E76F00"/>
        <path d="M9.48137 12.7124C9.48137 12.7124 8.86269 13.0722 9.92166 13.1939C11.2046 13.3403 11.8602 13.3193 13.274 13.0517C13.274 13.0517 13.6457 13.2848 14.1648 13.4866C10.9955 14.845 6.99205 13.408 9.48137 12.7124Z" fill="#5382A1"/>
        <path d="M9.094 10.9397C9.094 10.9397 8.4001 11.4533 9.45985 11.563C10.8303 11.7043 11.9126 11.7159 13.7853 11.3553C13.7853 11.3553 14.0443 11.6179 14.4516 11.7615C10.6197 12.882 6.35172 11.8499 9.094 10.9397Z" fill="#5382A1"/>
        <path d="M12.3588 7.93295C13.1398 8.83203 12.1537 9.6411 12.1537 9.6411C12.1537 9.6411 14.1365 8.61747 13.2259 7.33565C12.3754 6.14028 11.7232 5.54635 15.2541 3.49854C15.2541 3.49854 9.71169 4.88277 12.3588 7.93295Z" fill="#E76F00"/>
        <path d="M16.5495 14.0234C16.5495 14.0234 17.0073 14.4006 16.0453 14.6925C14.2159 15.2467 8.43124 15.414 6.82428 14.7146C6.24662 14.4633 7.3299 14.1145 7.67065 14.0413C8.02603 13.9643 8.22911 13.9786 8.22911 13.9786C7.5867 13.5261 4.07681 14.8672 6.44626 15.2513C12.9081 16.2993 18.2256 14.7794 16.5495 14.0234Z" fill="#5382A1"/>
        <path d="M9.77838 9.10322C9.77838 9.10322 6.83593 9.8021 8.73638 10.0559C9.53881 10.1633 11.1384 10.139 12.6284 10.0142C13.8462 9.91145 15.0689 9.69305 15.0689 9.69305C15.0689 9.69305 14.6395 9.87694 14.3289 10.0891C11.3409 10.8749 5.5686 10.5093 7.23036 9.70549C8.63571 9.02616 9.77838 9.10322 9.77838 9.10322Z" fill="#5382A1"/>
        <path d="M15.0571 12.0536C18.0946 10.4752 16.6902 8.95845 15.7099 9.1628C15.4697 9.2128 15.3626 9.25613 15.3626 9.25613C15.3626 9.25613 15.4517 9.11641 15.6221 9.05593C17.5613 8.37415 19.0527 11.0667 14.9961 12.1332C14.9961 12.1332 15.0431 12.0912 15.0571 12.0536Z" fill="#5382A1"/>
        <path d="M13.2248 0.191895C13.2248 0.191895 14.907 1.87464 11.6293 4.46224C9.00099 6.53793 11.03 7.72144 11.6282 9.07364C10.094 7.68941 8.96813 6.47086 9.72348 5.33675C10.8321 3.67198 13.9035 2.86484 13.2248 0.191895Z" fill="#E76F00"/>
        <path d="M10.076 16.3308C12.9915 16.5174 17.4687 16.2272 17.5747 14.8477C17.5747 14.8477 17.3709 15.3706 15.1652 15.786C12.6767 16.2543 9.60749 16.1996 7.78711 15.8995C7.78711 15.8995 8.15976 16.2079 10.076 16.3308Z" fill="#5382A1"/>
        <path d="M9.59823 21.602C9.32319 21.8406 9.0327 21.9745 8.77202 21.9745C8.39993 21.9745 8.1989 21.7514 8.1989 21.3935C8.1989 21.0064 8.41429 20.7234 9.27842 20.7234H9.59819L9.59823 21.602ZM10.3572 22.4583V19.8077C10.3572 19.1306 9.97097 18.6838 9.03986 18.6838C8.49628 18.6838 8.02012 18.8181 7.63311 18.9892L7.74482 19.4584C8.04953 19.3466 8.44396 19.2425 8.8311 19.2425C9.36748 19.2425 9.59823 19.4584 9.59823 19.9049V20.2399H9.33052C8.02732 20.2399 7.43945 20.7458 7.43945 21.5051C7.43945 22.1604 7.82686 22.5329 8.55628 22.5329C9.02515 22.5329 9.37516 22.3393 9.70235 22.056L9.76174 22.4583L10.3572 22.4583Z" fill="#E76F00"/>
        <path d="M12.8904 22.4583H11.9439L10.8047 18.751H11.6313L12.3382 21.0288L12.4953 21.7138C12.8529 20.7234 13.1058 19.7185 13.2323 18.751H14.0364C13.821 19.9718 13.4333 21.3118 12.8904 22.4583Z" fill="#E76F00"/>
        <path d="M16.5226 21.602C16.2471 21.8406 15.9561 21.9745 15.6954 21.9745C15.3239 21.9745 15.1224 21.7514 15.1224 21.3935C15.1224 21.0064 15.3383 20.7234 16.2023 20.7234H16.5226V21.602ZM17.2815 22.4583V19.8077C17.2815 19.1306 16.8943 18.6838 15.9643 18.6838C15.4203 18.6838 14.944 18.8181 14.5572 18.9892L14.6687 19.4584C14.9735 19.3466 15.3687 19.2425 15.7555 19.2425C16.2915 19.2425 16.5226 19.4584 16.5226 19.9049V20.2399H16.2545C14.9509 20.2399 14.3633 20.7458 14.3633 21.5051C14.3633 22.1604 14.7504 22.5329 15.4797 22.5329C15.9488 22.5329 16.2987 22.3393 16.6264 22.056L16.686 22.4583L17.2815 22.4583Z" fill="#E76F00"/>
        <path d="M6.60821 23.088C6.39186 23.4039 6.04242 23.6538 5.65986 23.7952L5.28516 23.3542C5.57634 23.2048 5.82607 22.9635 5.9421 22.7388C6.04247 22.5389 6.08401 22.2812 6.08401 21.6651V17.4326H6.89032V21.6071C6.89028 22.4308 6.82443 22.7635 6.60821 23.088Z" fill="#E76F00"/>
    </svg>
);

export {Java};