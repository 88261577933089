import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';

type SectionLabelType = 'dark' | 'light';

interface IProps {
    type: SectionLabelType;
    children: string;
};

const SectionLabel: React.FC<IProps> = (props) => {

    return (
        <div className={clsx(styles.sectionLabel, props.type === 'dark' ? styles.dark : styles.light)}>{props.children}</div>
    );
}

export {SectionLabel};