export const burgerLinks = [
    {
        name: 'Компания',
        to: 'about'
    },
    {
        name: 'Услуги',
        to: 'services',
        sublist: [
            {name: 'Разработка ПО',
            to: 'Разработка ПО'},
            {name: 'DevOps',
            to: 'DevOps'},
            {name: 'QA тестирование',
            to: 'QA тестирование'},
            {name: 'Архитектура',
            to: 'Архитектура'},
            {name: 'Процессы разработки',
            to: 'Процессы разработки (Delivery)'},
        ]
    },
    {
        name: 'Вакансии',
        to: 'vacancies'
    },
    {
        name: 'Компания',
        to: 'contactUs'
    },
]