import React from 'react';

const CloseBtn: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clip-path="url(#clip0_573_11050)">
            <path d="M18.2987 5.71022C17.9087 5.32022 17.2787 5.32022 16.8887 5.71022L11.9988 10.5902L7.10875 5.70021C6.71875 5.31021 6.08875 5.31021 5.69875 5.70021C5.30875 6.09021 5.30875 6.72022 5.69875 7.11022L10.5888 12.0002L5.69875 16.8902C5.30875 17.2802 5.30875 17.9102 5.69875 18.3002C6.08875 18.6902 6.71875 18.6902 7.10875 18.3002L11.9988 13.4102L16.8887 18.3002C17.2787 18.6902 17.9087 18.6902 18.2987 18.3002C18.6887 17.9102 18.6887 17.2802 18.2987 16.8902L13.4087 12.0002L18.2987 7.11022C18.6787 6.73022 18.6787 6.09022 18.2987 5.71022Z" fill="#423D4D"/>
        </g>
        <defs>
            <clipPath id="clip0_573_11050">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export {CloseBtn};