import React from 'react';

const BlockChain: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M6.23579 13.8177C6.13005 13.8177 6.02246 13.7918 5.9236 13.7364C5.61208 13.5639 5.50012 13.1713 5.67317 12.8603L8.19524 8.27722C8.36778 7.96687 8.76038 7.8524 9.0714 8.0268C9.38292 8.19917 9.49504 8.59194 9.32199 8.90296L6.79976 13.486C6.6821 13.698 6.46256 13.8177 6.23579 13.8177Z" fill="#A8EBFA"/>
        <path d="M15.7652 13.8177C15.5386 13.8177 15.3189 13.698 15.2012 13.486L12.679 8.90296C12.5059 8.59194 12.6181 8.19917 12.9296 8.0268C13.2411 7.8524 13.6332 7.96687 13.8057 8.27722L16.3278 12.8603C16.5009 13.1711 16.3889 13.5639 16.0774 13.7364C15.9785 13.7918 15.8709 13.8177 15.7652 13.8177Z" fill="#76E2F8"/>
        <path d="M14.2227 16.1992C14.2227 16.5601 13.9392 16.8438 13.5781 16.8438H8.42188C8.06084 16.8438 7.77734 16.5601 7.77734 16.1992C7.77734 15.8382 8.06084 15.5547 8.42188 15.5547H13.5781C13.9392 15.5547 14.2227 15.8382 14.2227 16.1992Z" fill="#A8EBFA"/>
        <path d="M14.2227 16.1992C14.2227 16.5601 13.9392 16.8438 13.5781 16.8438H11V15.5547H13.5781C13.9392 15.5547 14.2227 15.8382 14.2227 16.1992Z" fill="#76E2F8"/>
        <path d="M21.9097 14.3172L19.7012 14.2656L17.4453 16.8438L16.1562 19.3574L17.4453 22C17.5613 22 17.6644 21.9742 17.7676 21.9097L21.6907 19.6152C21.7165 19.6023 21.7422 19.5766 21.768 19.5637C21.9097 19.4348 22 19.2414 22 19.061V14.6394C22 14.5234 21.9742 14.4075 21.9097 14.3172Z" fill="#5A69E6"/>
        <path d="M15.1895 14.2656L13.0239 14.3172C12.9594 14.4204 12.9336 14.5234 12.9336 14.6394V19.0608C12.9336 19.2414 13.0239 19.4348 13.1656 19.5637C13.1914 19.5765 17.123 21.9097 17.123 21.9097C17.2263 21.9742 17.3293 22 17.4453 22V16.8438L15.1895 14.2656Z" fill="#7984EB"/>
        <path d="M21.9092 14.3172L17.4449 16.8438L13.0234 14.3172C13.0362 14.3044 13.0491 14.2785 13.062 14.2656C13.1136 14.1882 13.178 14.1367 13.2554 14.0852L17.1226 11.7778C17.2258 11.7133 17.3416 11.6875 17.4449 11.6875C17.5481 11.6875 17.6639 11.7133 17.7671 11.7778L21.6773 14.0852C21.7547 14.1367 21.8191 14.2012 21.8706 14.2656C21.8836 14.2785 21.8963 14.3044 21.9092 14.3172Z" fill="#979FEF"/>
        <path d="M8.9761 14.3172L6.81055 14.2656L4.55469 16.8438L3.26562 19.3574L4.55469 22C4.67067 22 4.77373 21.9742 4.87695 21.9097L8.75706 19.6152C8.78291 19.6023 8.80859 19.5766 8.83444 19.5637C8.9761 19.4348 9.06641 19.2414 9.06641 19.061V14.6394C9.06641 14.5234 9.04056 14.4075 8.9761 14.3172Z" fill="#5A69E6"/>
        <path d="M2.29883 14.2656L0.0903015 14.3172C0.0258484 14.4204 0 14.5234 0 14.6394V19.0608C0 19.2414 0.0903015 19.4348 0.231964 19.5637C0.257812 19.5765 4.23242 21.9097 4.23242 21.9097C4.33565 21.9742 4.43871 22 4.55469 22V16.8438L2.29883 14.2656Z" fill="#7984EB"/>
        <path d="M8.97565 14.3172L4.55423 16.8438L0.0898438 14.3172C0.1026 14.3044 0.115524 14.2785 0.128449 14.2656C0.179977 14.1882 0.244431 14.1367 0.321808 14.0852L4.23196 11.7778C4.33519 11.7133 4.451 11.6875 4.55423 11.6875C4.65746 11.6875 4.77327 11.7133 4.8765 11.7778L8.74368 14.0852C8.82106 14.1367 8.88551 14.2012 8.93704 14.2656C8.94997 14.2785 8.96272 14.3044 8.97565 14.3172Z" fill="#B5BAF3"/>
        <path d="M15.4214 2.67262L13.2559 2.62109L11 5.19922L9.71094 7.71289L11 10.3555C11.116 10.3555 11.219 10.3296 11.3223 10.2652L15.2024 7.9707C15.2282 7.95778 15.2539 7.9321 15.2798 7.91917C15.4214 7.79027 15.5117 7.59691 15.5117 7.41647V2.99489C15.5117 2.87891 15.4859 2.76292 15.4214 2.67262Z" fill="#5A69E6"/>
        <path d="M8.74414 2.62109L6.57858 2.67262C6.51413 2.77585 6.48828 2.87891 6.48828 2.99489V7.41631C6.48828 7.59691 6.57858 7.79027 6.72025 7.91917C6.74609 7.93193 10.6777 10.2652 10.6777 10.2652C10.781 10.3296 10.884 10.3555 11 10.3555V5.19922L8.74414 2.62109Z" fill="#7984EB"/>
        <path d="M15.421 2.67262L10.9995 5.19922L6.57812 2.67262C6.59088 2.65987 6.60381 2.63402 6.61673 2.62109C6.66826 2.54372 6.73271 2.49219 6.81009 2.44066L10.6773 0.0903015C10.7805 0.0258484 10.8963 0 10.9995 0C11.1028 0 11.2186 0.0258484 11.3218 0.0903015L15.189 2.44066C15.2664 2.49219 15.3308 2.55664 15.3824 2.62109C15.3953 2.63402 15.408 2.65987 15.421 2.67262Z" fill="#B5BAF3"/>
        <path d="M15.4214 2.67262L11 5.19922V0C11.1032 0 11.219 0.0258484 11.3223 0.0903015L15.1895 2.44066C15.2668 2.49219 15.3313 2.55664 15.3828 2.62109C15.3957 2.63402 15.4085 2.65987 15.4214 2.67262Z" fill="#979FEF"/>
    </svg>
);

export {BlockChain};