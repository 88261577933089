import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as AboutImg } from '../../../../assets/images/about-img.svg';
import { SectionLabel } from '../../../../components/SectionLabel/SectionLabel';

const About: React.FC = () => {
    return (
        <div className={styles.about} id='about'>
            <div className={styles.content}>
                <AboutImg />
                <div className={styles.text}>
                    <SectionLabel type='dark'>о нас</SectionLabel>
                    <h2 className={styles.sectionTitle}>Компания</h2>
                    <p className={styles.sectionText}>Мы — команда IT-специалистов, предлагающая современные решения для ваших бизнес-потребностей.</p>
                    <p className={styles.sectionText}>В Comm-IT вы получите качественный IT-сервис и комплексный подход на всех этапах работы.</p>
                </div>
            </div>
        </div>
    );
}

export {About};