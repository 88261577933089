import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    title: string;
    card: {
        title: string;
        listItems: string[];
        stack?: {
            name: string;
            icon: React.FC;
        }[];
    };
};

const ServiceCard: React.FC<IProps> = (props) => {
    return (
        <div className={styles.serviceCard} id={props.title}>
            <h3 className={styles.title}>{props.title}</h3>
            <ul className={styles.taskList}>
                {props.card.listItems.map((listItem: string) => (
                    <li key={listItem} className={styles.taskListItem}>{listItem}</li>
                ))}
            </ul>
            {props.card.stack && (
                <div className={styles.stack}>
                    <p className={styles.stackTitle}>Наш стек</p>
                    <ul className={styles.stackList}>
                        {props.card.stack.map((item) => (
                            <li key={item.name} className={styles.stackListItem}>
                                <div className={styles.stackListItemIcon}><item.icon /></div>
                                <p className={styles.stackListItemName}>{item.name}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            
        </div>
    );
}

export {ServiceCard};