import React from 'react';

const Angular: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
        <path d="M9.0001 0.879883L0.0625 4.06708L1.4257 15.8847L9.0001 20.0799L16.5745 15.8847L17.9377 4.06708L9.0001 0.879883Z" fill="#DD0031"/>
        <path d="M9 0.879883V3.01108V3.00148V12.7263V20.0799L16.5744 15.8847L17.9376 4.06708L9 0.879883Z" fill="#C3002F"/>
        <path d="M8.99931 3.00146L3.41211 15.5295H5.49531L6.61851 12.7263H11.3609L12.4841 15.5295H14.5673L8.99931 3.00146ZM10.6313 10.9983H7.36731L8.99931 7.07187L10.6313 10.9983Z" fill="white"/>
    </svg>
);

export {Angular};