export const vacancies = [
    {
        id: 1,
        name: 'Frontend-разработчик',
        where: 'Удаленно',
        industry: 'Разработка',
        experience: 'от 6 лет'
    },
    {
        id: 2,
        name: 'AQA Engineer (Android)',
        where: 'Удаленно',
        industry: 'Тестирование',
        experience: 'от 3 лет'
    },
    {
        id: 3,
        name: 'AQA Engineer (Android)',
        where: 'Удаленно',
        industry: 'Тестирование',
        experience: 'от 3 лет'
    },
    {
        id: 4,
        name: 'Promo Designer',
        where: 'Удаленно',
        industry: 'Дизайн',
        experience: 'от 1 года'
    }
];
