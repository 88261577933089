import React from 'react';

const Logo: React.FC = () => (
    <svg width="135" height="25" viewBox="0 0 135 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.66 8.94C16.3 7.28 14.62 6.45 12.62 6.45C11.74 6.45 10.92 6.61 10.16 6.93C9.42 7.25 8.78 7.69 8.24 
                8.25C7.7 8.79 7.27 9.44 6.95 10.2C6.65 10.96 6.5 11.78 6.5 12.66C6.5 13.56 6.65 14.39 6.95 15.15C7.27 15.91 
                7.7 16.57 8.24 17.13C8.8 17.69 9.45 18.13 10.19 18.45C10.93 18.77 11.73 18.93 12.59 18.93C14.47 18.93 16.16 
                18.13 17.66 16.53V23.49L17.06 23.7C16.16 24.02 15.32 24.25 14.54 24.39C13.76 24.55 12.99 24.63 12.23 24.63C10.67 
                24.63 9.17 24.34 7.73 23.76C6.31 23.16 5.05 22.33 3.95 21.27C2.87 20.19 2 18.92 1.34 17.46C0.68 15.98 0.35 14.37 
                0.35 12.63C0.35 10.89 0.67 9.3 1.31 7.86C1.97 6.4 2.84 5.15 3.92 4.11C5.02 3.05 6.29 2.23 7.73 1.65C9.17 1.05 
                10.68 0.749999 12.26 0.749999C13.16 0.749999 14.04 0.849999 14.9 1.05C15.78 1.23 16.7 1.52 17.66 1.92V8.94ZM25.9998 
                16.23C25.9998 16.75 26.0898 17.23 26.2698 17.67C26.4698 18.09 26.7198 18.46 27.0198 18.78C27.3398 19.1 27.7098 19.35 
                28.1298 19.53C28.5698 19.71 29.0298 19.8 29.5098 19.8C29.9898 19.8 30.4398 19.71 30.8598 19.53C31.2998 19.35 31.6698 
                19.1 31.9698 18.78C32.2898 18.46 32.5398 18.09 32.7198 17.67C32.9198 17.23 33.0198 16.76 33.0198 16.26C33.0198 
                15.78 32.9198 15.33 32.7198 14.91C32.5398 14.47 32.2898 14.09 31.9698 13.77C31.6698 13.45 31.2998 13.2 30.8598 
                13.02C30.4398 12.84 29.9898 12.75 29.5098 12.75C29.0298 12.75 28.5698 12.84 28.1298 13.02C27.7098 13.2 27.3398 
                13.45 27.0198 13.77C26.7198 14.09 26.4698 14.46 26.2698 14.88C26.0898 15.3 25.9998 15.75 25.9998 16.23ZM20.2698 
                16.17C20.2698 15.03 20.4998 13.97 20.9598 12.99C21.4198 11.99 22.0598 11.13 22.8798 10.41C23.6998 9.67 24.6698 
                9.09 25.7898 8.67C26.9298 8.25 28.1698 8.04 29.5098 8.04C30.8298 8.04 32.0498 8.25 33.1698 8.67C34.3098 9.07 
                35.2898 9.64 36.1098 10.38C36.9498 11.1 37.5998 11.97 38.0598 12.99C38.5198 13.99 38.7498 15.1 38.7498 16.32C38.7498 
                17.54 38.5098 18.66 38.0298 19.68C37.5698 20.68 36.9298 21.55 36.1098 22.29C35.2898 23.01 34.2998 23.57 33.1398 
                23.97C31.9998 24.37 30.7598 24.57 29.4198 24.57C28.0998 24.57 26.8798 24.37 25.7598 23.97C24.6398 23.57 23.6698 
                23 22.8498 22.26C22.0498 21.52 21.4198 20.64 20.9598 19.62C20.4998 18.58 20.2698 17.43 20.2698 16.17ZM41.8756 
                8.61H47.3056V10.5C48.7856 9.08 50.4456 8.37 52.2856 8.37C54.5656 8.37 56.3056 9.33 57.5056 11.25C58.7256 9.31 
                60.4656 8.34 62.7256 8.34C63.4456 8.34 64.1256 8.45 64.7656 8.67C65.4056 8.87 65.9556 9.2 66.4156 9.66C66.8756 
                10.1 67.2356 10.68 67.4956 11.4C67.7756 12.12 67.9156 12.99 67.9156 14.01V24H62.4856V16.41C62.4856 15.63 62.4256 
                14.99 62.3056 14.49C62.2056 13.99 62.0456 13.6 61.8256 13.32C61.6256 13.04 61.3856 12.85 61.1056 12.75C60.8256 
                12.65 60.5256 12.6 60.2056 12.6C58.4656 12.6 57.5956 13.87 57.5956 16.41V24H52.1656V16.41C52.1656 15.63 52.1156 
                14.99 52.0156 14.49C51.9156 13.97 51.7656 13.56 51.5656 13.26C51.3856 12.96 51.1456 12.76 50.8456 12.66C50.5456 
                12.54 50.1956 12.48 49.7956 12.48C49.4556 12.48 49.1356 12.53 48.8356 12.63C48.5356 12.73 48.2656 12.93 48.0256 
                13.23C47.8056 13.51 47.6256 13.91 47.4856 14.43C47.3656 14.93 47.3056 15.59 47.3056 16.41V24H41.8756V8.61ZM71.6413 
                8.61H77.0713V10.5C78.5513 9.08 80.2113 8.37 82.0513 8.37C84.3313 8.37 86.0713 9.33 87.2713 11.25C88.4913 9.31 
                90.2313 8.34 92.4913 8.34C93.2113 8.34 93.8913 8.45 94.5313 8.67C95.1713 8.87 95.7213 9.2 96.1813 9.66C96.6413 
                10.1 97.0013 10.68 97.2613 11.4C97.5413 12.12 97.6813 12.99 97.6813 14.01V24H92.2513V16.41C92.2513 15.63 92.1913 
                14.99 92.0713 14.49C91.9713 13.99 91.8113 13.6 91.5913 13.32C91.3913 13.04 91.1513 12.85 90.8713 12.75C90.5913 
                12.65 90.2913 12.6 89.9713 12.6C88.2313 12.6 87.3613 13.87 87.3613 16.41V24H81.9313V16.41C81.9313 15.63 81.8813 
                14.99 81.7813 14.49C81.6813 13.97 81.5313 13.56 81.3313 13.26C81.1513 12.96 80.9113 12.76 80.6113 12.66C80.3113 
                12.54 79.9613 12.48 79.5613 12.48C79.2213 12.48 78.9013 12.53 78.6012 12.63C78.3013 12.73 78.0313 12.93 77.7913 
                13.23C77.5713 13.51 77.3913 13.91 77.2513 14.43C77.1313 14.93 77.0713 15.59 77.0713 16.41V24H71.6413V8.61Z" 
            fill="black"
        />
        <path 
            d="M99.5469 13.71H108.157V18.24H99.5469V13.71ZM116.41 1.38V24H110.53V1.38H116.41ZM129.716 
                6.36V24H123.836V6.36H119.006V1.38H134.546V6.36H129.716Z" 
            fill="#2667FF"
        />
    </svg>
);

export {Logo};