import React, { useState, FormEvent, ChangeEvent } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ArrowRight } from "../../../../assets/icons/ArrowRight";
import { RequestConfirmationPopup } from "./components/RequestConfirmationPopup/RequestConfirmationPopup";

const ContactUs: React.FC = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setIsConfirmationModalOpen(true);
    console.log(formData);
  };

  return (
    <div className={styles.contactUs} id="contactUs">
      <div className={styles.text}>
        <h2 className={styles.sectionTitle}>Свяжитесь с нами</h2>
        <p className={styles.sectionText}>И мы обсудим первые шаги:</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Как улучшить вашу IT инфраструктуру
          </li>
          <li className={styles.listItem}>
            С чего начать интеграцию CRM систем
          </li>
          <li className={styles.listItem}>
            Как организовать машинное обучение для вашего бизнеса
          </li>
        </ul>
      </div>
      <form action="#" className={styles.form} onSubmit={handleSubmit}>
        <input
          name="name"
          className={clsx(styles.input, styles.nameInput)}
          placeholder="Имя"
          type="text"
          onChange={handleChange}
        />
        <input
          name="email"
          className={clsx(styles.input, styles.emailInput)}
          placeholder="Электронная почта"
          type="email"
          onChange={handleChange}
        />
        <textarea
          name="message"
          className={clsx(styles.input, styles.otherInput)}
          placeholder="Как мы можем вам помочь?"
          onChange={handleChange}
        />
        <button type="submit" className={styles.submitButton}>
          <p className={styles.buttonText}>Отправить</p>
          <ArrowRight />
        </button>
      </form>
      <RequestConfirmationPopup
        isOpen={isConfirmationModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        status="success"
      />
    </div>
  );
};

export { ContactUs };
