import React from 'react';

const TestRail: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 15 23" fill="none">
        <path d="M0.578198 19.638L3.08867 22.1485C3.21337 22.2732 3.41554 22.2732 3.54023 22.1485L6.05071 19.638C6.1754 19.5133 6.1754 19.3111 6.0507 19.1865L3.54023 16.676C3.41554 16.5513 3.21337 16.5513 3.08867 16.676L0.5782 19.1865C0.453505 19.3111 0.453503 19.5133 0.578198 19.638Z" fill="#65C179"/>
        <path d="M4.63484 15.581L7.14532 18.0915C7.27001 18.2162 7.47218 18.2162 7.59687 18.0915L10.1073 15.581C10.232 15.4563 10.232 15.2541 10.1073 15.1294L7.59688 12.619C7.47218 12.4943 7.27001 12.4943 7.14532 12.619L4.63484 15.1295C4.51015 15.2541 4.51015 15.4563 4.63484 15.581Z" fill="#65C179"/>
        <path d="M8.69148 11.5239L11.202 14.0344C11.3267 14.159 11.5288 14.159 11.6535 14.0344L14.164 11.5239C14.2887 11.3992 14.2887 11.197 14.164 11.0723L11.6535 8.56185C11.5288 8.43715 11.3266 8.43715 11.202 8.56185L8.69148 11.0723C8.56679 11.197 8.56679 11.3992 8.69148 11.5239Z" fill="#65C179"/>
        <path d="M0.652421 11.6001L3.16289 14.1105C3.28759 14.2352 3.48976 14.2352 3.61445 14.1105L6.12492 11.6001C6.24962 11.4754 6.24962 11.2732 6.12493 11.1485L3.61445 8.63802C3.48976 8.51333 3.28759 8.51333 3.16289 8.63802L0.652422 11.1485C0.527727 11.2732 0.527726 11.4754 0.652421 11.6001Z" fill="#65C179"/>
        <path d="M4.71101 7.54305L7.22149 10.0535C7.34618 10.1782 7.54835 10.1782 7.67305 10.0535L10.1835 7.54304C10.3082 7.41835 10.3082 7.21618 10.1835 7.09149L7.67305 4.58101C7.54835 4.45632 7.34618 4.45632 7.22148 4.58101L4.71101 7.09148C4.58632 7.21618 4.58632 7.41835 4.71101 7.54305Z" fill="#65C179"/>
        <path d="M0.728593 3.56209L3.23907 6.07256C3.36376 6.19726 3.56593 6.19725 3.69062 6.07256L6.2011 3.56209C6.32579 3.43739 6.32579 3.23522 6.2011 3.11053L3.69062 0.600057C3.56593 0.475362 3.36376 0.475363 3.23907 0.600058L0.728594 3.11053C0.603899 3.23522 0.603898 3.43739 0.728593 3.56209Z" fill="#65C179"/>
    </svg>
);

export {TestRail};