import React from 'react';

const ELK: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 13.6107H20.6507V8.448H0V13.6107Z" fill="#00BFB3"/>
        <path d="M0 1.936C0 0.86678 0.86678 0 1.936 0H18.7147C19.7839 0 20.6507 0.86678 20.6507 1.936V5.808H0V1.936Z" fill="#F04E98"/>
        <path d="M0 16.192H20.6507V20.064C20.6507 21.1333 19.7839 22 18.7147 22H1.936C0.86678 22 0 21.1333 0 20.064V16.192Z" fill="#0077CC"/>
    </svg>
);

export {ELK};
