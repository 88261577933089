import React from 'react';
import styles from './styles.module.scss';
import { About } from './components/About/About';
import { Services } from './components/Services/Services';
import { Projects } from './components/Projects/Projects';
import { Vacancies } from './components/Vacancies/Vacancies';
import { ContactUs } from './components/ContactUs/ContactUs';
import { FirstScreen } from './components/FirstScreen/FirstScreen';

const Main: React.FC = () => {
    return (
        <div className={styles.main}>
            <FirstScreen />
            <About />
            <Services />
            <Projects />
            <Vacancies />
            <ContactUs />
        </div>
    );
}

export {Main};