import React from 'react';
import styles from './styles.module.scss';
import { ArrowRight } from '../../../../../../assets/icons/ArrowRight';

interface IProps {
    name: string;
    where: string;
    industry: string;
    experience: string;
};

const VacancyCard: React.FC<IProps> = (props) => {
    return (
        <div className={styles.vacancyCard}>
            <h3 className={styles.title}>{props.name}</h3>
            <p className={styles.conditions}>{props.where} &middot; {props.industry} &middot; Опыт {props.experience}</p>
            <button className={styles.button}>
                <p className={styles.buttonText}>Подробнее</p>
                <ArrowRight />
            </button>
        </div>
    );
}

export {VacancyCard};