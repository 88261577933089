import React from 'react';
import styles from './styles.module.scss';
import { Link } from "react-scroll";
import Drawer from '@mui/material/Drawer';
import { Logo } from '../../../../assets/icons/Logo';
import { CloseBtn } from '../../../../assets/icons/CloseBtn';
import { burgerLinks } from './constants';
import { styled } from '@mui/material/styles';

interface IProps {
    isOpen: boolean;
    setIsBurgerOpen: any;
};

const StyledDrawer = styled(Drawer)(() => ({
    '& > .MuiPaper-root': {
        height: 'fit-content',
    },
}));

const BurgerMenu: React.FC<IProps> = ({isOpen, setIsBurgerOpen}) => {
    const handleClose = () => {
        setIsBurgerOpen(false)
    }

	return (
        <StyledDrawer
            open={isOpen}
            onClose={handleClose}
        >
            <div className={styles.container}>
                <div className={styles.heading}>
                    <Link to="first-screen" smooth={true} duration={500} offset={-72} onClick={handleClose}><Logo /></Link>
                    <button className={styles.button} onClick={() => handleClose()}><CloseBtn/></button>
                </div>
                <nav className={styles.anchors}>
                    {burgerLinks.map((link, index) => (
                        <div key={`${link.name}-${index}`}>
                            <Link
                                to={link.to}
                                className={styles.listItem}
                                smooth={true}
                                duration={500}
                                offset={-72}
                                onClick={handleClose}
                            >
                                {link.name}
                            </Link>
                            {link.sublist && (
                                <div className={styles.subanchors}>
                                    {link.sublist.map((sublink, subIndex) => (
                                        <Link
                                            key={`${sublink.name}-${subIndex}`}
                                            to={sublink.to}
                                            className={styles.sublistItem}
                                            smooth={true}
                                            duration={500}
                                            offset={-72}
                                            onClick={handleClose}
                                        >
                                            {sublink.name}
                                        </Link>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </nav>
            </div>
        </StyledDrawer>
	);
}

export {BurgerMenu};
