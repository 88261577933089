import { Java } from "../../../../assets/icons/Java";
import { Spring } from "../../../../assets/icons/Spring";
import { ReactComponent as ReactIcon} from '../../../../assets/icons/ReactIcon.svg';
import { Angular } from "../../../../assets/icons/Angular";
import { Vue } from "../../../../assets/icons/Vue";
import { KeyCloack} from '../../../../assets/icons/KeyCloack';
import { BlockChain } from "../../../../assets/icons/BlockChain";
import { Android } from "../../../../assets/icons/Android";
import { Ios } from "../../../../assets/icons/Ios";
import { PostgreSQL} from '../../../../assets/icons/PostgreSQL';
import { RabbitMQ } from "../../../../assets/icons/RabbitMQ";
import { ReactComponent as Redis} from '../../../../assets/icons/Redis.svg';
import { Kubernetes } from "../../../../assets/icons/Kubernetes";
import { ELK } from "../../../../assets/icons/ELK";
import { Prometheus} from '../../../../assets/icons/Prometheus';
import { VueGrafana } from "../../../../assets/icons/VueGrafana";
import { Selenium} from '../../../../assets/icons/Selenium';
import { TestRail } from "../../../../assets/icons/TestRail";
import { JMeter } from "../../../../assets/icons/JMeter";
import { WireMock} from '../../../../assets/icons/WireMock';

export const services = [
    {
        title: 'Разработка ПО',
        listItems: [
            'WEB и мобильные приложения',
            'CRM',
            'ESB (корпоративные шины)',
            'Внедрение SSO на базе KeyCloak',
            'Разработка BlockChain приложений',
            'Машинное обучение под нужды бизнеса'
        ],
        stack: [
            {name: 'Java', icon: Java},
            {name: 'Spring', icon: Spring},
            {name: 'React', icon: ReactIcon},
            {name: 'Angular', icon: Angular},
            {name: 'Vue', icon: Vue},
            {name: 'KeyCloack', icon: KeyCloack},
            {name: 'BlockChain', icon: BlockChain},
            {name: 'Android', icon: Android},
            {name: 'iOS', icon: Ios},
            {name: 'PostgreSQL', icon: PostgreSQL},
            {name: 'RabbitMQ', icon: RabbitMQ},
            {name: 'Redis', icon: Redis},
        ]
    },
    {
        title: 'DevOps',
        listItems: [
            'Консалтинговые услуги по внедрению DevOps',
            'Автоматизация CI/CD',
            'Разработка инфраструктурных ботов',
            'Внедрение DevSecOps инструментов и практик',
        ],
        stack: [
            {name: 'DockerSpring', icon: Spring},
            {name: 'Kubernetes', icon: Kubernetes},
            {name: 'ELK', icon: ELK},
            {name: 'Prometheus', icon: Prometheus},
            {name: 'Vue.grafana', icon: VueGrafana},
            {name: 'Redis', icon: Redis},
        ]
    },
    {
        title: 'QA тестирование',
        listItems: [
            'Ручное тестирование',
            'Автоматизированное тестирование',
            'Нагрузочное тестирование',
        ],
        stack: [
            {name: 'Selenium', icon: Selenium},
            {name: 'TestRail', icon: TestRail},
            {name: 'jMeter', icon: JMeter},
            {name: 'WireMock', icon: WireMock},
        ]
    },
    {
        title: 'Архитектура',
        listItems: [
            'Аудит архитектуры',
            'Разработка enterprise и solution архитектуры',
            'Разработка архитектурных тестов (ArchUnit)',
        ]
    },
    {
        title: 'Процессы разработки (Delivery)',
        listItems: [
            'Аудит процессов разработки',
            'Разработки и внедрение процессов',
        ]
    }
]