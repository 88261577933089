import { Java } from "../../../../assets/icons/Java";
import { Spring } from "../../../../assets/icons/Spring";
import { ReactComponent as ReactIcon} from '../../../../assets/icons/ReactIcon.svg';
import { KeyCloack} from '../../../../assets/icons/KeyCloack';
import { ReactComponent as OtpImg } from '../../../../assets/images/otp-logo.svg';
import MkbImg from '../../../../assets/images/mkb-logo.png';

export const projects = [
    {
        logo: OtpImg,
        description: 'Разработки системы дистанционного банковского обслуживания юридических лиц (Интернет Банка ЮЛ).',
        stack: [
            {name: 'Java', icon: Java},
            {name: 'Spring', icon: Spring},
            {name: 'React', icon: ReactIcon},
            {name: 'KeyCloack', icon: KeyCloack},
        ]
    },
    {
        logo: MkbImg,
        description: 'Внедрение системы сквозной авторизации (SSO)',
        stack: [
            {name: 'React', icon: ReactIcon},
            {name: 'Java', icon: Java},
            {name: 'Spring', icon: Spring},
            {name: 'KeyCloack', icon: KeyCloack},
        ]
    },

]