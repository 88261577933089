import React from 'react';
import styles from './styles.module.scss';

interface IProps {
    item: {
        logo: React.FC | string;
        description: string;
        stack: {
            name: string;
            icon: React.FC;
        }[];
    };
}

const ProjectCard: React.FC<IProps> = (props) => {
    const { item } = props;
    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                {typeof item.logo === 'string' ? <img width="260px" src={item.logo} alt='MKB' /> : <item.logo />}
            </div>
            <div className={styles.content}>
                <div className={styles.description}>{item.description}</div>
                {item.stack && (
                    <div className={styles.stack}>
                        <ul className={styles.stackList}>
                            {item.stack.map((item) => (
                                <li key={item.name} className={styles.stackListItem}>
                                    <div className={styles.stackListItemIcon}><item.icon /></div>
                                    <p className={styles.stackListItemName}>{item.name}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export { ProjectCard };