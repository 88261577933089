import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Logo } from '../../assets/icons/Logo';
import { Link } from "react-scroll";
import clsx from 'clsx';
import { ReactComponent as BurgerMenuButton } from '../../assets/icons/burgerMenu.svg'
import { BurgerMenu } from './components/BurgerMenu/BurgerMenu';

const Header: React.FC = () => {
	const [isFixed, setIsFixed] = useState(false);
	const [isBurgerOpen, setIsBurgerOpen] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY || document.documentElement.scrollTop;
			setIsFixed(scrollPosition > 700);
		};
	
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header className={clsx(isFixed ? styles.headerFixed : styles.header)}>
			<div className={styles.content}>
			<Link to="first-screen" smooth={true} duration={500}><Logo /></Link>
				<nav className={styles.anchors}>
					<Link to="about" className={styles.anchor} activeClass={styles.active} smooth={true} duration={500} offset={-72} spy={true}>Компания</Link>
					<Link to="services" className={styles.anchor} activeClass={styles.active} smooth={true} duration={500} offset={-72} spy={true}>Услуги</Link>
					<Link to="projects" className={styles.anchor} activeClass={styles.active} smooth={true} duration={500} offset={-72} spy={true}>Проекты</Link>
					<Link to="vacancies" className={styles.anchor} activeClass={styles.active} smooth={true} duration={500} offset={-72} spy={true}>Вакансии</Link>
					<Link to="contactUs" className={styles.anchor} activeClass={styles.active} smooth={true} duration={500} offset={-72} spy={true}>Контакты</Link>
				</nav>
				<BurgerMenuButton className={styles.burger} onClick={() => setIsBurgerOpen(true)}/>
			</div>
			<BurgerMenu isOpen={isBurgerOpen} setIsBurgerOpen={setIsBurgerOpen} />
		</header>
	);
}

export {Header};