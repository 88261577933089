import React from "react";
import styles from "./styles.module.scss";
import { Logo } from "../../assets/icons/Logo";
import { Facebook } from "../../assets/icons/Facebook";
import { Twitter } from "../../assets/icons/Twitter";
import { Linkedin } from "../../assets/icons/Linkedin";
import { Youtube } from "../../assets/icons/Youtube";
import { Phone } from "../../assets/icons/Phone";
import { Mail } from "../../assets/icons/Mail";
import { Location } from "../../assets/icons/Location";
import { Link } from "react-scroll";

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <div className={styles.contacts}>
                    <div className={styles.links}>
                        <Logo />
                        {/*<div className={styles.socials}>*/}
                        {/*    <a href="#" className={styles.social}><Facebook /></a>*/}
                        {/*    <a href="#" className={styles.social}><Twitter /></a>*/}
                        {/*    <a href="#" className={styles.social}><Linkedin /></a>*/}
                        {/*    <a href="#" className={styles.social}><Youtube /></a>*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.contactsInfo}>
                        <div className={styles.contact}>
                            <div className={styles.logo}>
                                <Phone />
                            </div>
                            <a href="tel:+79991112666">+7 999 111 26 66</a>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.logo}>
                                <Mail />
                            </div>
                            <a href="mailto:info@comm-it.pro">info@comm-it.pro</a>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.logo}>
                                <Location />
                            </div>
                            300000, Тульская обл, г Тула, Центральный р-н, ул Макса Смирнова,
                            д 2, офис 3
                        </div>
                        <div className={styles.contact}>
                            <span>ИНН</span>
                            7100039290
                        </div>
                    </div>
                </div>
                <div className={styles.anchors}>
                    <Link
                        to="about"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Компания
                    </Link>
                    <Link
                        to="projects"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Проекты
                    </Link>
                    <Link
                        to="vacancies"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Вакансии
                    </Link>
                    <Link
                        to="contactUs"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Контакты
                    </Link>
                </div>
                <div className={styles.servicesWrapper}>
                    <Link
                        to="services"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Услуги
                    </Link>
                    <ul className={styles.services}>
                        <li className={styles.service}>
                            <Link
                                to="Разработка ПО"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                Разработка ПО
                            </Link>
                        </li>
                        <li className={styles.service}>
                            <Link to="DevOps" smooth={true} duration={500} offset={-72}>
                                DevOps
                            </Link>
                        </li>
                        <li className={styles.service}>
                            <Link
                                to="QA тестирование"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                QA тестирование
                            </Link>
                        </li>
                        <li className={styles.service}>
                            <Link to="Архитектура" smooth={true} duration={500} offset={-72}>
                                Архитектура
                            </Link>
                        </li>
                        <li className={styles.service}>
                            <Link
                                to="Процессы разработки (Delivery)"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                Процессы разработки
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.productsWrapper}>
                    <Link
                        to="products"
                        className={styles.anchor}
                        smooth={true}
                        duration={500}
                        offset={-72}
                        spy={true}
                    >
                        Продукты
                    </Link>
                    <ul className={styles.products}>
                        <li className={styles.product}>
                            <Link
                                to="Разработка ПО"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                Разработка ПО
                            </Link>
                        </li>
                        <li className={styles.product}>
                            <Link to="DevOps" smooth={true} duration={500} offset={-72}>
                                DevOps
                            </Link>
                        </li>
                        <li className={styles.product}>
                            <Link
                                to="QA тестирование"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                QA тестирование
                            </Link>
                        </li>
                        <li className={styles.product}>
                            <Link to="Архитектура" smooth={true} duration={500} offset={-72}>
                                Архитектура
                            </Link>
                        </li>
                        <li className={styles.product}>
                            <Link
                                to="Процессы разработки (Delivery)"
                                smooth={true}
                                duration={500}
                                offset={-72}
                            >
                                Процессы разработки
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export { Footer };
