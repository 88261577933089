import React from 'react';
import styles from './styles.module.scss';
import { Modal } from '@mui/material';
import { ReactComponent as ConfirmationImg } from '../../../../../../assets/images/confirmation-img.svg';
import { ReactComponent as ConfirmationErrorImg } from '../../../../../../assets/images/confirmation-fail-img.svg';

interface IProps {
    isOpen: boolean;
    setIsConfirmationModalOpen: any;
    status: string;
};

const RequestConfirmationPopup: React.FC<IProps> = ({isOpen, setIsConfirmationModalOpen, status}) => {
    const handleClose = () => {
        setIsConfirmationModalOpen(false)
    }

    const successMessage = () => (
        <>
            <ConfirmationImg />
            <h3 className={styles.title}>Ваша заявка отправлена</h3>
            <p className={styles.subtitle}>Мы свяжемся с вами в течение двух дней</p>
        </>
    );

    const errorMessage = () => (
        <>
            <ConfirmationErrorImg />
            <h3 className={styles.title}>Что-то пошло не так</h3>
            <p className={styles.subtitle}>Попробуйте ещё раз</p>
        </>
    );

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <div className={styles.container}>
                {status === 'success' ? successMessage() : errorMessage()}
                <button className={styles.button} onClick={() => handleClose()}>Закрыть</button>
            </div>
        </Modal>
    );
}

export {RequestConfirmationPopup};